import styled, { createGlobalStyle, css } from 'styled-components';
import ReactModal from 'react-modal';
import {
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
} from '../../../utils/helpers';
import backgroundCurves from '../../images/bg/background-curves.webp';
import backgroundCurvesTwo from '../../images/bg/background-curves-2.webp';

export const GlobalStyle = createGlobalStyle`
	.portal-class{
		.ReactModal__Overlay {
			opacity: 0;
			transition: opacity 500ms ease-in-out;
			animation: fade-in-two 300ms ease-in-out;
		}

		.ReactModal__Overlay--after-open {
			opacity: 1;
		}

		.ReactModal__Overlay--before-close {
			opacity: 0;
		}
		@keyframes fade-in-two {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}
	}
`;

export const HeroWrapper = styled.div`
	${mediaQuery.mobileXL`
		margin-bottom: 20px;
	`}
`;

export const SiriWrapper = styled.div`
	canvas {
		width: 100% !important;
	}
	${mediaQuery.mobileXL`
		display: none;
	`}
`;
export const BgWrapper = styled.div`
	background-image: url('${backgroundCurves}');
	background-size: 106% 100%;
`;
export const BGWrapperTwo = styled.div`
	background-image: url('${backgroundCurvesTwo}');
	background-size: 114% 100%;
	background-position: center;
`;

export const SectionWrapper = styled.div`
	margin: 50px 0 0 0;
	${mediaQuery.mobileXL`
		margin: 0 0 20px 0;
	`}
`;
export const ContentWrapper = styled.div`
	.section-header {
		& > div {
			margin: 0;
		}
		h2 {
			line-height: 1.11;
			margin: 0 auto 30px auto;
		}
		p {
			line-height: 1.3;
		}
	}
	.m-w-900 {
		h2 {
			max-width: 900px;
		}
	}
	.disable-grid {
		display: block;
	}
	${mediaQuery.tabletL`
		.section-header {
			h2 {
				font-size: ${setFontSize.veryLarge};
			}
			p{
				font-size: ${setFontSize.mediumTiny};
			}
		}
	`}
	${mediaQuery.mobileXL`
		.section-header {
			h2 {
				font-size: ${setFontSize.mediumSmall};
			}
		}
	`}
`;

export const CustomerWrapper = styled.div`
	section {
		background: none;
		& > div {
			padding: 0 !important;
		}
	}
	${mediaQuery.tabletL`
		section{
			& > div {
				h3{
					font-size: ${setFontSize.veryLarge};
				}
			}
		}
	`}
	${mediaQuery.mobileXL`
		section{
			& > div {
				h3{
					font-size: ${setFontSize.mediumSmall};
				}
			}
		}
	`}
`;

export const FaqWrapper = styled.div`
	${mediaQuery.tabletL`
		padding-bottom: 20px;
	`}
	${mediaQuery.mobileXL`
		padding-bottom: 0;
	`}
`;

export const FeatureSnippetThreeHolder = styled.div`
	padding-top: 150px;
	padding-bottom: 7px;
	${mediaQuery.laptopXL`
		padding-top: 100px;
	`}
	${mediaQuery.mobileXL`
		padding-top: 70px;
		padding-bottom: 15px;
	`}
`;

export const StyledReactModal = styled(ReactModal)`
	.modal-content {
		position: relative;
		max-width: 100%;
		height: 100%;
		border: none;
		background-color: #000;
		padding-bottom: 56.25%;
		height: 0;
		${({ borderRadius }) =>
			borderRadius
				? css`
						border-radius: ${borderRadius};
				  `
				: ``}}
	}
	iframe {
		padding: 0;
		margin: 0;
		outline: none;
		border: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.modal-default {
		transition: 0.4s ease-in-out;
		animation: fade-in 0.4s ease-in-out;
		transform: scale(0.9);
		opacity: 0;
	}

	.open {
		transform: scale(1);
		opacity: 1;
	}
	.close-button {
		position: absolute;
		top: -43px;
		right: -13px;
		z-index: 100;
		width: 40px;
		height: 40px;
		text-align: center;
		border-radius: 50%;
		border: 2px solid transparent;
		line-height: 38px;
		padding: 0;
		background: transparent;
		-webkit-appearance: none;
		cursor: pointer;
		opacity: 0.65;
		font-size: ${setFontSize.small};
		color: ${setColors.white};
		font-family: 'arial', ${setFontFamily.book};
		transition: all 0.3s ease-out;
		&:hover {
			border: 2px solid ${setColors.white};
			transform: scale(0.8) rotateZ(90deg);
		}
		&:hover,
		&:focus {
			opacity: 1;
		}
	}

	@keyframes fade-in {
		from {
			transform: scale(0.9);
			opacity: 0;
		}
		to {
			transform: scale(1);
			opacity: 1;
		}
	}
`;
